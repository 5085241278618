import { getStore } from "app/configureStore";
import env from "app/utils/env";
import { isAuthenticated } from "app/pages/Auth/authSelectors";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const IS_DEFAULT_LISTING_VIEW_LIST = false;

export const shouldGetProfileAtPayment = () => {
	const state = getStore().getState();
	return isAuthenticated(state);
};

export const getProfileApiUrl = () => {
	return `${env("USER_AUTH_API_URL")}/me`;
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};
